import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Spin,
} from "antd";

import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentCounterToCounter, profile, counters }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values.title = "Transfer between cashboxes";
    const counter1Data = counters.find((c) => c._id === values.counter1) || {};
    const counter2Data = counters.find((c) => c._id === values.counter2) || {};
    values.counter1 = { id: counter1Data._id, title: counter1Data.title };
    values.counter2 = { id: counter2Data._id, title: counter2Data.title };
    values.from = counter1Data._id;
    values.to = counter2Data._id;

    const result = await paymentCounterToCounter(values);

    if (result.success) {
      form.resetFields();
      message.success("Transfer made!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to transfer!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!counters) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counters]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Make Transfer between Cashboxes</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Enter the transfer amount!",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Amount" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select the transfer method!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Transfer Method"
                optionFilterProp="children"
              >
                <Option value="cash">
                  <h4 className="option-title-font">cash</h4>
                </Option>
                <Option value="bank account">
                  <h4 className="option-title-font">bank account</h4>
                </Option>
                <Option value="card transfer">
                  <h4 className="option-title-font">card transfer</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Transfer Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cashbox/Account for debiting"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Select a cashbox/account for debiting!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Cashbox/account"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Cashbox/Account for crediting"
            name="counter2"
            rules={[
              {
                required: true,
                message: "Select a cashbox/account for crediting!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Cashbox/account"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Make Transfer
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
