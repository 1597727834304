import axios from "axios";

import { SET_STOREHOUSE } from "./types";
import { SET_RECEIPT_ORDERS } from "./types";
import { SET_DINMEL_PRICES } from "./types";

const apiURL = process.env.API_URL;

const setStorehouse = (storehouse) => {
  return {
    type: SET_STOREHOUSE,
    storehouse,
  };
};

export function getStorehouse() {
  return async (dispatch, getState) => {
    const state = await getState();
    try {
      const response = await axios.get(`${apiURL}/storehouse`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setStorehouse(response.data.storehouse));
    } catch (error) {
      console.error("Error in getStorehouse: ", error.message);
    }
  };
}

export function createCategory(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/storehouse/category/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createCategory", error.message);
      return error.response.data;
    }
  };
}

export function editCategory(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/storehouse/category/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editCategory", error.message);
      return error.response.data;
    }
  };
}

export function removeCategory(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.delete(
        `${apiURL}/storehouse/category/delete`,
        {
          headers: {
            Authorization: state.auth.token,
          },
          data: values,
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }

      return response.data;
    } catch (error) {
      console.error("Error in removeCategory", error.response.data.message);
      return error.response.data;
    }
  };
}
export function createStorehouseInstance(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/storehouse/instance/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createStorehouseInstance", error.message);
      return error.response.data;
    }
  };
}

export function editStorehouseInstance(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/storehouse/instance/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editStorehouseInstance", error.message);
      return error.response.data;
    }
  };
}

export function removeStorehouseInstance(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.delete(
        `${apiURL}/storehouse/instance/delete`,
        {
          headers: {
            Authorization: state.auth.token,
          },
          data: values,
        }
      );

      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in removeStorehouseInstance", error.message);
      return error.response.data;
    }
  };
}
export function writeOffStorehouseInstance(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/storehouse/instance/write_off`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in writeOffStorehouseInstance", error.message);
      return error.response.data;
    }
  };
}

const setReceiptOrders = (receiptOrders) => {
  return {
    type: SET_RECEIPT_ORDERS,
    receiptOrders,
  };
};

export function getReceiptOrders() {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/storehouse/receiptorders`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setReceiptOrders(response.data.receiptOrders));
    } catch (error) {
      console.error("Error in getReceiptOrders: ", error.message);
    }
  };
}
export function initialRawMaterials(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/storehouse/settings/initial/raw-materials`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getReceiptOrders());
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in initialRawMaterials", error.message);
      return error.response.data;
    }
  };
}

export function initialProducts(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/storehouse/settings/initial/products`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in initialProducts", error.message);
      return error.response.data;
    }
  };
}

export function createReceiptOrder(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/storehouse/receiptorder/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getReceiptOrders());
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createReceiptOrder", error.message);
      return error.response.data;
    }
  };
}

export function editReceiptOrder(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/storehouse/receiptorder/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getReceiptOrders());
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editReceiptOrder", error.message);
      return error.response.data;
    }
  };
}

export function cancelReceiptOrder(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/storehouse/receiptorder/cancel`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getReceiptOrders());
        dispatch(getStorehouse());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelReceiptOrder", error.message);
      return error.response.data;
    }
  };
}

export function recountStorehouse() {
  return async (dispatch, getState) => {
    const state = await getState();
    try {
      const response = await axios.get(`${apiURL}/storehouse/recount`, {
        headers: {
          Authorization: state.auth.token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error in recountStorehouse: ", error.message);
      return error.response.data;
    }
  };
}

const setDinMelPrices = (prices) => {
  return {
    type: SET_DINMEL_PRICES,
    prices,
  };
};

export function getDinMelPrices() {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${apiURL}/din-mel/prices`);

      dispatch(setDinMelPrices(response.data.prices));
    } catch (error) {
      console.error("Error in getDinMelPrices: ", error.message);
      return error.response.data;
    }
  };
}

export function editDinMelPrice(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/din-mel/prices/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      dispatch(getDinMelPrices());
      return response.data;
    } catch (error) {
      console.error("Error in editDinMelPrice", error.message);
      return error.response.data;
    }
  };
}
