import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ writeOffStorehouseInstance, profile }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const instance = location.state.instance;

  form.setFieldsValue({
    title: instance.title,
    totalAvailable: instance.weight.totalAvailable,
  });

  const onFinish = async (values) => {
    setLoading(true);

    values._id = instance._id;
    values.creatorId = profile._id;
    var result = await writeOffStorehouseInstance(values);

    if (result.success) {
      message.success("Write-off successful!");
    } else {
      message.error(result.message || "Failed to write off!");
      setLoading(false);
    }
  };

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
          Write off {instance.type === "product" ? "product" : "raw material"}
        </h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item label="Title" disabled={true} name="title">
            <Input />
          </Form.Item>
          <Form.Item
            label="Total Available"
            disabled={true}
            name="totalAvailable"
          >
            <Input />
          </Form.Item>
          <Form.Item
            requiredMark="hidden"
            label="Amount to Write Off"
            name="weight"
            rules={[
              {
                required: true,
                message: "Please enter the write-off amount!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Write Off
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
