import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";
import { useHistory } from "react-router-dom";
import {
  Table,
  Card,
  Modal,
  Button,
  Space,
  Input,
  message,
  Radio,
  Spin,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FieldNumberOutlined,
  CreditCardOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import "./index.css";

const { formatDate } = commonFunc;

export default ({ receiptOrders, cancelReceiptOrder, profile }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReceiptOrder, setSelectedReceiptOrder] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [columnsForModal, setColumnsForModal] = useState([]);
  const [columns, setColumns] = useState([]);

  function comeback() {
    history.push("/storehouse");
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const filterReceiptOrders = (filter, receiptOrders, searchValue) => {
    let filteredData = receiptOrders || [];

    if (searchValue) {
      filteredData = filteredData.filter((receiptOrder) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          receiptOrder.counteragent.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          receiptOrder.counteragent.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          receiptOrder.counteragent.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          receiptOrder.counteragent.firm
            ?.toLowerCase()
            .includes(searchValueLower) ||
          receiptOrder.comment?.toLowerCase().includes(searchValueLower) ||
          receiptOrder.serial === searchNumber ||
          receiptOrder.totalWeight === searchNumber ||
          receiptOrder.totalSum === searchNumber
        );
      });
    }

    switch (filter) {
      case "opened":
        return filteredData.filter((rO) => rO.state === "opened");
      case "complete":
        return filteredData.filter((rO) => rO.state === "complete");
      case "cancelled":
        return filteredData.filter((rO) => rO.cancelled);
      case "notPayed":
        return filteredData.filter((rO) => !rO.paid && !rO.cancelled);
      default:
        return filteredData;
    }
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((receiptOrder, index) => ({
      ...receiptOrder,
      key: receiptOrder._id,
      date: formatDate(receiptOrder.date).slice(0, 100),
      counteragentName: receiptOrder.counteragent
        ? `${receiptOrder.counteragent.firm || ""} ${
            receiptOrder.counteragent.lastname || ""
          } ${receiptOrder.counteragent.firstname || ""}`
        : "",
      driverName: receiptOrder.driver
        ? `${receiptOrder.driver.firm || ""} ${
            receiptOrder.driver.lastname || ""
          }
    ${receiptOrder.driver.firstname || ""} ${
      receiptOrder.driver.additionalName || ""
    }`
        : "",
      deliveryCost: receiptOrder.deliveryCost
        ? `${receiptOrder.deliveryCost || ""}`
        : "",
      payments: receiptOrder.payments
        .map((payment) => {
          if (!payment.cancelled) {
            return {
              ...payment,
            };
          }
          return null;
        })
        .filter(Boolean),
      totalSumPaid: `${receiptOrder.totalSumPaid} / ${receiptOrder.totalSum}`,
      instances: receiptOrder.instances.map((instance, idx) => ({
        ...instance,
        key: `${receiptOrder._id}-${idx}`,
        cost: Number((instance.price * instance.weight).toFixed(2)),
      })),
    }));
  };

  const handleEditReceiptOrder = async (receiptOrder) => {
    history.push({
      pathname: `/storehouse/receiptorder/edit`,
      state: {
        receiptOrder,
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handlePayForReceiptOrder = async (receiptOrder) => {
    history.push({
      pathname: `/counters/receiptorder`,
      state: {
        receiptOrder,
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleCancelReceiptOrder = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelReceiptOrder(values);

    if (result.success) {
      message.success("Receipt order successfully cancelled!");
    } else {
      message.error(result.message || "Failed to cancel!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (receiptOrder) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this receipt order?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, cancel",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleCancelReceiptOrder(receiptOrder);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (receiptOrder) => {
    setSelectedReceiptOrder(receiptOrder);
    setIsModalVisible(true);
  };

  const columnsForModalofPayments = [
    {
      title: (
        <div>
          <FieldNumberOutlined /> <CalendarOutlined />
        </div>
      ),
      dataIndex: "serial",
      key: "serial",
      render: (_, record) => (
        <span className="column-content-font">
          {record.serial} / {formatDate(record.date).slice(0, 100)}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Amount</span>,
      dataIndex: "sum",
      key: "sum",
      render: (text, record) =>
        record.cancelled ? (
          <span className="table-title-font">CANCELLED</span>
        ) : (
          <span className="column-content-font">{text} rub.</span>
        ),
    },
    {
      title: <span className="column-title-font">Where</span>,
      dataIndex: "counter1",
      key: "counter1",
      render: (text) => (
        <span className="column-content-font">{text.title} </span>
      ),
    },
    {
      title: <span className="column-title-font">Payment Method</span>,
      dataIndex: "type",
      key: "type",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
  ];

  const ReceiptOrderModalContent = () => {
    if (!selectedReceiptOrder) {
      return null;
    }

    const {
      date,
      serial,
      counteragentName,
      instances,
      totalSumPaid,
      totalSum,
      totalWeight,
      comment,
      cancelled,
      payments,
      driverName,
      deliveryCost,
    } = selectedReceiptOrder;

    return (
      <Card
        size="small"
        title={`Receipt Order №${serial}`}
        extra={
          <span className="column-title-font">
            Delivered: {cancelled ? "CANCELLED" : date.slice(0, 100)}
          </span>
        }
      >
        {counteragentName ? (
          <>
            <span className="column-title-font">Supplier:</span>
            <span className="column-content-font">{counteragentName}</span>
          </>
        ) : (
          <span className="column-title-font">Initial Stock Entry</span>
        )}
        <br />
        <br />
        <Table
          dataSource={instances}
          columns={columnsForModal}
          pagination={false}
          size="small"
        />

        <div className="common-modal__info-block">
          <div>
            <span className="column-title-font">Total Weight:</span>
            <span className="column-content-font">{totalWeight} kg</span>
          </div>

          <div>
            <span className="column-title-font">Total:</span>
            <span className="column-content-font">{totalSum} rub.</span>
          </div>
          <div>
            <span className="column-title-font">Paid:</span>
            {cancelled ? (
              <span className="column-title-font" style={{ color: "red" }}>
                Cancelled
              </span>
            ) : (
              <span className="column-content-font">{totalSumPaid} rub.</span>
            )}
          </div>
          {driverName && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Driver:</span>
              <span className="column-title-font">{driverName}</span>
            </div>
          )}
          {deliveryCost && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Delivery Cost:</span>
              <span className="column-title-font">{deliveryCost} rub.</span>
            </div>
          )}
          {comment && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Comment:</span>
              <span className="column-content-font">{comment}</span>
            </div>
          )}
          <h3 className="common-modal-title">Payments:</h3>
          <Table
            dataSource={payments}
            columns={columnsForModalofPayments}
            pagination={false}
            size="small"
          />
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!receiptOrders) {
      setLoading(true);
      return;
    }

    const filteredOrders = filterReceiptOrders(
      filter,
      receiptOrders,
      searchValue
    );

    const dataSource = prepareDataSource(filteredOrders);
    setDataSource(dataSource);

    setLoading(false);
  }, [filter, receiptOrders, searchValue]);

  useEffect(() => {
    if (history.location.state?.listViewState) {
      const { filter, isModalVisible, searchValue } =
        history.location.state.listViewState;
      setFilter(filter);
      setIsModalVisible(isModalVisible);
      setSearchValue(searchValue);
    }
  }, [history.location.state]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const columns = [
      {
        title: (
          <div>
            <FieldNumberOutlined /> <CalendarOutlined />
          </div>
        ),
        dataIndex: "serial",
        key: "serial",
        render: (text, record) => (
          <span className="column-content-font">
            {record.serial} / {record.date}
          </span>
        ),
      },
      {
        title: <span className="column-title-font">Weight</span>,
        dataIndex: "totalWeight",
        key: "totalWeight",
        render: (text) => <span className="column-content-font">{text}kg</span>,
      },
      {
        title: <span className="table-title-font">Amount</span>,
        dataIndex: "totalSum",
        key: "totalSum",
        render: (text, record) =>
          record.cancelled ? (
            <span className="table-title-font">CANCELLED</span>
          ) : (
            <span className="column-content-font">{text} rub.</span>
          ),
      },
      {
        title: <span className="column-title-font">Actions</span>,
        key: "action",
        render: (text, record) => (
          <Space>
            <Button
              disabled={
                record.state === "complete" ||
                record.cancelled ||
                record.type === "initialRawMaterials"
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={
                <CreditCardOutlined
                  style={{
                    color:
                      record.cancelled || record.type === "initialRawMaterials"
                        ? "inherit"
                        : record.paid
                          ? "green"
                          : "red",
                  }}
                />
              }
              onClick={() => handlePayForReceiptOrder(record)}
            />
            <Button
              className="common-table-button"
              size="small"
              type="link"
              onClick={() => showModal(record)}
              icon={<EyeOutlined />}
            />
            <Button
              disabled={
                record.state === "complete" ||
                record.cancelled ||
                record.type === "initialRawMaterials"
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEditReceiptOrder(record)}
            />
            <Button
              disabled={
                record.state === "complete" ||
                record.cancelled ||
                record.type === "initialRawMaterials"
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => showConfirmCancel(record)}
              danger
            />
          </Space>
        ),
      },
      {
        dataIndex: "state",
        key: "state",
        render: (text, record) => (
          <FileDoneOutlined
            style={{
              color: !record.paid
                ? "red"
                : record.cancelled
                  ? "grey"
                  : record.state === "complete"
                    ? "yellow"
                    : "",
            }}
          />
        ),
      },
    ];

    const columnsForModal = [
      {
        title: <span className="column-title-font">Name</span>,
        dataIndex: "title",
        key: "title",
        render: (text) => <span className="column-content-font">{text}</span>,
      },
      {
        title: <span className="column-title-font">Weight</span>,
        dataIndex: "weight",
        key: "weight",
        render: (text) => (
          <span className="column-content-font">{text} kg</span>
        ),
      },
      {
        title: <span className="column-title-font">Price</span>,
        dataIndex: "price",
        key: "price",
        render: (text) => (
          <span className="column-content-font">{text} rub</span>
        ),
      },
    ];

    const counteragentColumn = {
      title: <span className="column-title-font">Counteragent</span>,
      dataIndex: "counteragentName",
      key: "counteragentName",
      render: (text) => <span className="column-content-font">{text}</span>,
    };

    const costColumn = {
      title: <span className="column-title-font">Cost</span>,
      dataIndex: "cost",
      key: "cost",
      render: (text) => <span className="column-content-font">{text} rub</span>,
    };

    if (windowWidth > 375) {
      columns.splice(1, 0, counteragentColumn);
      columnsForModal.push(costColumn);
    }

    setColumns(columns);
    setColumnsForModal(columnsForModal);
  }, [windowWidth]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <Button type="ghost" onClick={comeback} className="back-route-button">
          <LeftOutlined />
          <h4 className="back-route-button--title">Back</h4>
        </Button>
        <br />
        <h4 className="common-title--large">All Receipt Orders</h4>
        <Input.Search
          placeholder="Search by last name/firm of counteragent/serial number"
          onChange={handleSearchChange}
          value={searchValue}
          style={{ marginBottom: 8 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value="opened">Opened</Radio.Button>
          <Radio.Button value="complete">Completed</Radio.Button>
          <Radio.Button value="cancelled">Cancelled</Radio.Button>
          <Radio.Button value="notPayed">Unpaid</Radio.Button>
        </Radio.Group>

        <Table
          rowClassName={(record) => {
            if (record.state === "complete") return "complete";
            if (record.paid) return "paid";
            if (record.cancelled) return "cancelled";
            return "";
          }}
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <ReceiptOrderModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
