import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import "./index.css";

export default function Select(props) {
  let history = useHistory();
  function comeback() {
    if (history.location.state?.from && history.location.state?.listViewState) {
      history.push(history.location.state.from, {
        listViewState: history.location.state.listViewState,
      });
    } else {
      history.goBack();
    }
  }
  return (
    <Button type="ghost" onClick={comeback} className="back-route-button">
      <LeftOutlined />
      <h4 className="back-route-button--title">back </h4>
    </Button>
  );
}
