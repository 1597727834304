import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Typography,
  Radio,
  Spin,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./index.css";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.locale("ru");
const { Option } = Select;
const { Title, Text } = Typography;

export default ({ editShift, profile, counteragents, storehouse, shifts }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [instances, setInstances] = useState(form.getFieldValue("instances"));
  const [shift, setShift] = useState(false);

  const onFinish = async (values) => {
    if (values.instances.length === 0) {
      message.error("Add at least one item!");
      return;
    }

    setLoading(true);
    values.creatorId = profile._id;
    values._id = shift._id;
    values.processings = shift.processings;

    const { totalWeight } = getTotalWeightAndSum(values.instances);
    values.totalWeight = totalWeight;

    values.instances = values.instances.map((instance) => {
      const selectedInstance = products.find(
        (inst) => inst._id === instance.id
      );
      return {
        id: selectedInstance._id,
        title: selectedInstance.title,
        weight: instance.weight,
      };
    });

    const workerRoles = [
      "senior",
      "junior1",
      "junior2",
      "junior3",
      "junior4",
      "junior5",
      "junior6",
      "porter1",
      "porter2",
      "porter3",
      "porter4",
      "porter5",
      "porter6",
      "loader",
      "helperOfTechnologist",
      "grainstoreWorker1",
      "grainstoreWorker2",
      "packer",
    ];

    values.workers = {};
    workerRoles.forEach((role) => {
      if (values[role]) {
        values.workers[role] = counteragents.find(
          (counteragent) => counteragent._id === values[role]
        );
      }
    });

    const result = await editShift(values);
    if (result.success) {
      form.resetFields();
      message.success("Shift successfully updated!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to update shift!");
      setLoading(false);
    }
  };

  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    return (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        acc.totalWeight += weight;
        return acc;
      },
      { totalWeight: 0 }
    );
  };

  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };

  const handleProductFilter = (input, option) => {
    const product = products.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.id === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("This type of product has already been added to the list.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                ...instance,
                id: null,
                weight: null,
                sizeOfPack: null,
              }
            : instance
        ),
      });
    }
  };

  const renderInstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>List of produced products:</Title>
        {fields.map((field, index) => (
          <Space.Compact block size="small" key={`${field.name}-${index}`}>
            <Form.Item
              {...field}
              name={[field.name, "id"]}
              key={[field.key, "id"]}
              style={{ minWidth: "50%", margin: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Select a product",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a product"
                filterOption={handleProductFilter}
                onSelect={(value) => handleInstanceSelect(value, field)}
              >
                {products.map((instance) => (
                  <Option key={instance._id} value={instance._id}>
                    <h4 className="option-title-font">{instance.title}</h4>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              {...field}
              style={{ width: "30%", margin: "0px" }}
              name={[field.name, "weight"]}
              key={[field.key, "weight"]}
              rules={[
                {
                  required: true,
                  message: "Enter the weight",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Weight"
                onChange={updateTotalWeightAndSum}
              />
            </Form.Item>
            <Form.Item style={{ margin: "0px" }} {...field}>
              <MinusCircleOutlined
                style={{ marginLeft: "4px", color: "#ff4d4f" }}
                onClick={() => operation.remove(field.name)}
              />
            </Form.Item>
          </Space.Compact>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Add product
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (shifts) {
      const id = location.state.shift._id;
      for (var shift of shifts) {
        if (shift._id === id) {
          setShift(shift);
          break;
        }
      }
    }
  }, [shifts]);

  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);
  useEffect(() => {
    if (storehouse) {
      let deepCopyOfStorehouse = JSON.parse(JSON.stringify(storehouse));

      var products = deepCopyOfStorehouse.productsCategories.flatMap(
        (category) => category.instances || []
      );

      for (var [index, product] of products.entries()) {
        product.title =
          product.title.replace(
            /пшеничная|марка|пшеничные|Хлебопекарная/g,
            ""
          ) +
          " " +
          product.sizeOfPack +
          "kg";

        products[index] = product;
      }

      const customSort = (a, b) => {
        const keywordsOrder = [
          "в/с 50",
          "в/с",
          "1/с",
          "2/с",
          "манная",
          "отруби",
        ];

        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        const includesBothA = titleA.includes("в/с") && titleA.includes("50");
        const includesBothB = titleB.includes("в/с") && titleB.includes("50");

        if (includesBothA && !includesBothB) {
          return -1;
        } else if (!includesBothA && includesBothB) {
          return 1;
        }

        if (titleA.includes("в/с 50") && !titleB.includes("в/с 50")) {
          return -1;
        } else if (!titleA.includes("в/с 50") && titleB.includes("в/с 50")) {
          return 1;
        }

        const indexA = keywordsOrder.findIndex((keyword) =>
          titleA.includes(keyword)
        );
        const indexB = keywordsOrder.findIndex((keyword) =>
          titleB.includes(keyword)
        );

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        return titleA.localeCompare(titleB);
      };
      products.sort(customSort);

      setProducts(products);
    }
  }, [storehouse]);

  useEffect(() => {
    setInstances(form.getFieldValue("instances"));
  }, [form]);

  useEffect(() => {
    const calculations = getTotalWeightAndSum(instances);
    form.setFieldsValue({
      totalWeight: calculations.totalWeight,
    });
  }, [instances]);

  useEffect(() => {
    if (!shift) return;
    var formattedDate = dayjs(shift.date).format("DD.MM.YYYY");
    formattedDate = dayjs(formattedDate, "DD.MM.YYYY");

    const instancesWithDetails = shift.instances.map((instance) => {
      return {
        ...instance,
        instance: instance.id,
      };
    });

    form.setFieldsValue({
      date: formattedDate,
      period: shift.period,
      repairTime: shift.repairTime,
      senior: shift.workers.senior?.id,
      junior1: shift.workers.junior1?.id,
      junior2: shift.workers.junior2?.id,
      junior3: shift.workers.junior3?.id,
      junior4: shift.workers.junior4?.id,
      junior5: shift.workers.junior5?.id,
      junior6: shift.workers.junior6?.id,
      porter1: shift.workers.porter1?.id,
      porter2: shift.workers.porter2?.id,
      porter3: shift.workers.porter3?.id,
      porter4: shift.workers.porter4?.id,
      porter5: shift.workers.porter5?.id,
      porter6: shift.workers.porter6?.id,
      loader: shift.workers.loader?.id,
      helperOfTechnologist: shift.workers.helperOfTechnologist?.id,
      grainstoreWorker1: shift.workers.grainstoreWorker1?.id,
      grainstoreWorker2: shift.workers.grainstoreWorker2?.id,
      packer: shift.workers.packer?.id,
      instances: instancesWithDetails,
      totalWeight: shift.totalWeight,
      comment: shift.comment,
    });
  }, [shift]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Edit Shift</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Date:"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select the production date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            name="period"
            label="Shift period:"
            rules={[
              {
                required: true,
                message: "Please select the shift period!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="day">Day 8:00 - 20:00</Radio>
              <Radio value="night">Night 20:00 - 8:00</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="repairTime"
            label="Repair time (if any), number of hours:"
          >
            <InputNumber
              size="small"
              style={{
                width: "10%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Shift leader:"
            name="senior"
            rules={[
              {
                required: true,
                message: "Please select the shift leader!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Select the shift leader"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Junior shift №1:"
            name="junior1"
            rules={[
              {
                required: true,
                message: "Please select the junior shift!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Junior shift №2:"
            name="junior2"
            rules={[
              {
                required: true,
                message: "Please select the junior shift!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Junior shift №3:" name="junior3">
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Junior shift №4:" name="junior4">
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Junior shift №5:" name="junior5">
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Junior shift №6:" name="junior6">
            <Select
              showSearch
              size="small"
              placeholder="Select the junior shift"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №1:" name="porter1">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №2:" name="porter2">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №3:" name="porter3">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №4:" name="porter4">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №5:" name="porter5">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Porter №6:" name="porter6">
            <Select
              showSearch
              size="small"
              placeholder="Select the porter"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Loader:" name="loader">
            <Select
              showSearch
              size="small"
              placeholder="Select the loader"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Assistant Technologist:"
            name="helperOfTechnologist"
          >
            <Select
              showSearch
              size="small"
              placeholder="Select the assistant technologist"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Grainstore worker №1:" name="grainstoreWorker1">
            <Select
              showSearch
              size="small"
              placeholder="Select the grainstore worker"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Grainstore worker №2:" name="grainstoreWorker2">
            <Select
              showSearch
              size="small"
              placeholder="Select the grainstore worker"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Packer:" name="packer">
            <Select
              showSearch
              size="small"
              placeholder="Select the packer"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "workshop worker" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>

          <Form.List
            initialValue={[
              {
                id: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderInstancesList(fields, operation)}
          </Form.List>

          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Total weight</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
