import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import groupsOfLinks from "src/data/groupsOfLinks";
import { getLast1000Actions } from "src/auth/redux/actions";

import { getCounteragents } from "src/counteragents/redux/actions";
import {
  getReceiptOrders,
  getStorehouse,
  getDinMelPrices,
} from "src/storehouse/redux/actions";
import { getOrders } from "src/orders/redux/actions";
import { getCounters } from "src/counters/redux/actions";
import {
  getShifts,
  getRepairs,
  getAnalyses,
} from "src/processing/redux/actions";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import SrorehouseScreen from "src/storehouse/components/StorehouseScreen";
import Layout from "src/common/components/Layout";
import RoutePage from "src/common/components/RoutePage";
import LoginScreen from "src/auth/components/LoginScreen";
import CabinetScreen from "src/auth/components/CabinetScreen";
import CreateStorehouseCategoryScreen from "src/storehouse/components/CreateStorehouseCategoryScreen";
import CreateStorehouseInstanceScreen from "src/storehouse/components/CreateStorehouseInstanceScreen";
import ShowAllCategoriesScreen from "src/storehouse/components/ShowAllCategoriesScreen";
import ShowAllInstancesScreen from "src/storehouse/components/ShowAllInstancesScreen";
import EditStorehouseCategoryScreen from "src/storehouse/components/EditStorehouseCategoryScreen";
import EditStorehouseInstanceScreen from "src/storehouse/components/EditStorehouseInstanceScreen";
import WriteOffScreen from "src/storehouse/components/WriteOffScreen";
import ProductsBalancesScreen from "src/storehouse/components/ProductsBalancesScreen";
import RawMaterialsBalancesScreen from "src/storehouse/components/RawMaterialsBalancesScreen";
import CreateCounteragentScreen from "src/counteragents/components/CreateCounteragentScreen";
import ShowAllCounteragentsScreen from "src/counteragents/components/ShowAllCounteragentsScreen";
import ShowCounteragentPaymentsScreen from "src/counteragents/components/ShowCounteragentPaymentsScreen";
import ShowCounteragentOrdersScreen from "src/counteragents/components/ShowCounteragentOrdersScreen";
import ShowCounteragentReceiptOrdersScreen from "src/counteragents/components/ShowCounteragentReceiptOrdersScreen";
import ShowCounteragentShiftsScreen from "src/counteragents/components/ShowCounteragentShiftsScreen";
import EditCounteragentScreen from "src/counteragents/components/EditCounteragentScreen";
import CreateReceiptOrderScreen from "src/storehouse/components/CreateReceiptOrderScreen";
import ShowAllReceiptOrdersScreen from "src/storehouse/components/ShowAllReceiptOrdersScreen";
import EditReceiptOrderScreen from "src/storehouse/components/EditReceiptOrderScreen";
import CreateShiftScreen from "src/processing/components/CreateShiftScreen";
import ShowAllShiftsScreen from "src/processing/components/ShowAllShiftsScreen";
import EditShiftScreen from "src/processing/components/EditShiftScreen";
import CreateRepairScreen from "src/processing/components/CreateRepairScreen";
import EditRepiarScreen from "src/processing/components/EditRepairScreen";
import ShowAllRepairsScreen from "src/processing/components/ShowAllRepairsScreen";
import CreateAnalysisScreen from "src/processing/components/CreateAnalysisScreen";
import EditAnalysisScreen from "src/processing/components/EditAnalysisScreen";
import ShowAllAnalysesScreen from "src/processing/components/ShowAllAnalysesScreen";
import CreateOrderScreen from "src/orders/components/CreateOrderScreen";
import ShowAllOrdersScreen from "src/orders/components/ShowAllOrdersScreen";
import EditOrderScreen from "src/orders/components/EditOrderScreen";
import PayForReceiptOrderScreen from "src/counters/components/PayForReceiptOrderScreen";
import PayForOrderScreen from "src/counters/components/PayForOrderScreen";
import PaymentCounteragentToCounterScreen from "src/counters/components/PaymentCounteragentToCounterScreen";
import PaymentCounterToCounteragentScreen from "src/counters/components/PaymentCounterToCounteragentScreen";
import PaymentCounterToCounterScreen from "src/counters/components/PaymentCounterToCounterScreen";
import AddOnBalanceOfCounteragentScreen from "src/counters/components/AddOnBalanceOfCounteragentScreen";
import PaymentToCounterScreen from "src/counters/components/PaymentToCounterScreen";
import PaymentFromCounterScreen from "src/counters/components/PaymentFromCounterScreen";
import ShowAllCountersScreen from "src/counters/components/ShowAllCountersScreen";
import InitialProductsScreen from "src/storehouse/components/InitialProductsScreen";
import InitialRawMaterialsScreen from "src/storehouse/components/InitialRawMaterialsScreen";
import ShowAllDinMelPricesScreen from "src/storehouse/components/ShowAllDinMelPricesScreen";
import EditPriceforDinMelScreen from "src/storehouse/components/EditPriceforDinMelScreen";
function Routes() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/counteragents") {
      dispatch(getCounteragents());
    }
    if (location.pathname === "/counteragents/all") {
      dispatch(getCounteragents());
    }
    if (location.pathname === "/storehouse") {
      dispatch(getCounteragents());
      dispatch(getStorehouse());
      dispatch(getDinMelPrices());
    }
    if (location.pathname === "/processing/shifts") {
      dispatch(getStorehouse());
    }
    if (location.pathname === "/processing/analysis/show") {
      dispatch(getAnalyses());
    }
    if (location.pathname === "/processing/repair/show") {
      dispatch(getRepairs());
    }
    if (location.pathname === "/storehouse/receiptorders") {
      dispatch(getReceiptOrders());
    }
    if (location.pathname === "/processing") {
      dispatch(getStorehouse());
      dispatch(getCounteragents());
      dispatch(getShifts());
      dispatch(getRepairs());
      dispatch(getAnalyses());
    }
    if (location.pathname === "/orders") {
      dispatch(getOrders());
      dispatch(getStorehouse());
      dispatch(getCounteragents());
    }
    if (location.pathname === "/orders/all") {
      dispatch(getStorehouse());
      dispatch(getOrders());
    }
    if (location.pathname === "/counters") {
      dispatch(getCounters());
      dispatch(getStorehouse());
      dispatch(getCounteragents());
    }
    if (location.pathname === "/cabinet") {
      dispatch(getLast1000Actions());
    }
  }, [location]);
  return (
    <Layout>
      <Switch>
        <Route path="/" exact component={LoginScreen}></Route>
        <Route path="/storehouse" exact component={SrorehouseScreen}></Route>
        <Route path="/cabinet" exact component={CabinetScreen}></Route>
        <Route
          path="/storehouse/settings/category/create"
          exact
          component={CreateStorehouseCategoryScreen}
        ></Route>
        <Route
          path="/storehouse/settings/instance/create"
          exact
          component={CreateStorehouseInstanceScreen}
        ></Route>
        <Route
          path="/storehouse/settings/categories"
          exact
          component={ShowAllCategoriesScreen}
        ></Route>
        <Route
          path="/storehouse/settings/instances"
          exact
          component={ShowAllInstancesScreen}
        ></Route>
        <Route
          path="/storehouse/settings/category/edit"
          component={EditStorehouseCategoryScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/settings/instance/edit"
          component={EditStorehouseInstanceScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/settings/instance/write_off"
          component={WriteOffScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/products"
          component={ProductsBalancesScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/rawmaterials"
          component={RawMaterialsBalancesScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/create"
          component={CreateCounteragentScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/get/payments"
          component={ShowCounteragentPaymentsScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/get/orders"
          component={ShowCounteragentOrdersScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/get/receiptorders"
          component={ShowCounteragentReceiptOrdersScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/get/shifts"
          component={ShowCounteragentShiftsScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/edit"
          component={EditCounteragentScreen}
          exact
        ></Route>
        <Route
          path="/counteragents/:filter"
          component={ShowAllCounteragentsScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/receiptorder/create"
          component={CreateReceiptOrderScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/receiptOrders"
          exact
          component={ShowAllReceiptOrdersScreen}
        ></Route>
        <Route
          path="/storehouse/receiptorder/edit"
          component={EditReceiptOrderScreen}
          exact
        ></Route>
        <Route
          path="/processing/shift/create"
          component={CreateShiftScreen}
          exact
        ></Route>
        <Route
          path="/processing/shifts"
          component={ShowAllShiftsScreen}
          exact
        ></Route>
        <Route
          path="/processing/shift/edit"
          component={EditShiftScreen}
          exact
        ></Route>
        <Route
          path="/processing/repair/create"
          component={CreateRepairScreen}
          exact
        ></Route>
        <Route
          path="/processing/repair/edit"
          component={EditRepiarScreen}
          exact
        ></Route>
        <Route
          path="/processing/repair/show"
          component={ShowAllRepairsScreen}
          exact
        ></Route>
        <Route
          path="/processing/analysis/create"
          component={CreateAnalysisScreen}
          exact
        ></Route>
        <Route
          path="/processing/analysis/show"
          component={ShowAllAnalysesScreen}
          exact
        ></Route>
        <Route
          path="/processing/analysis/edit"
          component={EditAnalysisScreen}
          exact
        ></Route>
        <Route
          path="/orders/create"
          component={CreateOrderScreen}
          exact
        ></Route>
        <Route
          path="/counters/receiptorder"
          component={PayForReceiptOrderScreen}
          exact
        ></Route>
        <Route
          path="/counters/order"
          component={PayForOrderScreen}
          exact
        ></Route>
        <Route
          path="/counters/payment/counteragent-to-counter"
          component={PaymentCounteragentToCounterScreen}
          exact
        ></Route>
        <Route
          path="/counters/payment/counter-to-counteragent"
          component={PaymentCounterToCounteragentScreen}
          exact
        ></Route>
        <Route
          path="/counters/payment/add-on-balance-of-counteragent"
          component={AddOnBalanceOfCounteragentScreen}
          exact
        ></Route>
        <Route
          path="/counters/payment/to-counter"
          component={PaymentToCounterScreen}
          exact
        ></Route>
        <Route
          path="/counters/payment/from-counter"
          component={PaymentFromCounterScreen}
          exact
        ></Route>
        <Route
          path="/counters/counter-to-counter"
          component={PaymentCounterToCounterScreen}
          exact
        ></Route>
        <Route
          path="/counters/all"
          component={ShowAllCountersScreen}
          exact
        ></Route>
        <Route path="/orders/edit" component={EditOrderScreen} exact></Route>
        <Route path="/orders/all" component={ShowAllOrdersScreen} exact></Route>
        <Route
          path="/storehouse/settings/initial/products"
          component={InitialProductsScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/settings/initial/raw-materials"
          component={InitialRawMaterialsScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/settings/din-mel/prices"
          component={ShowAllDinMelPricesScreen}
          exact
        ></Route>
        <Route
          path="/storehouse/settings/din-mel/prices/edit"
          component={EditPriceforDinMelScreen}
          exact
        ></Route>
        {groupsOfLinks.map((link, index) => {
          return (
            <Route
              path={link.prefix}
              key={index}
              render={() => <RoutePage values={link} />}
              exact
            ></Route>
          );
        })}
      </Switch>
    </Layout>
  );
}

export default Routes;
