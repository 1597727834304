import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, Form, Input, Spin } from "antd";

import "./index.css";

function Login({ login }) {
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  const handleLogin = async (values) => {
    setLoading(true);
    var result = await login({
      login: values.login,
      password: values.password,
    });
    if (result.success) {
      history.push("/cabinet");
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="login--container">
      <Spin spinning={loading}>
        <Form
          onFinish={handleLogin}
          name="login"
          labelCol={{ span: 6 }}
          autoComplete="off"
        >
          <Form.Item label="Login" name="login">
            <Input />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
            }}
          >
            <Button htmlType="submit">Login</Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default Login;
