import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Spin,
} from "antd";
import SelectorWithAddingOptions from "src/common/components/SelectorWithAddingOptions";

import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentFromCounter, profile, counters }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;

    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};
    values.counter1 = { id: _id, title };
    values.from = _id;

    const result = await paymentFromCounter(values);

    if (result.success) {
      form.resetFields();
      message.success("Payment made!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to make payment!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!counters) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counters]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Make Payment from Cashbox</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Purpose"
            style={{ width: "90%", margin: "0px" }}
            name="title"
            rules={[
              {
                required: true,
                message: "Please select the purpose of the payment!",
              },
            ]}
          >
            <SelectorWithAddingOptions
              placeholder="Payment Purpose"
              listOfOptions={[
                "service payment",
                "tax payment",
                "rent payment",
                "utility payment",
                "supplier payment",
                "transportation service payment",
                "salary payment to salaried employees",
                "sick leave payment",
                "vacation payment",
              ]}
              onChange={(value) => form.setFieldsValue({ title: value })}
            />
          </Form.Item>
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Enter the payment amount!",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Amount" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select the payment method!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Payment Method"
                optionFilterProp="children"
              >
                <Option value="cash">
                  <h4 className="option-title-font">cash</h4>
                </Option>
                <Option value="bank account">
                  <h4 className="option-title-font">bank account</h4>
                </Option>
                <Option value="card transfer">
                  <h4 className="option-title-font">card transfer</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Payment Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cashbox/Account for debiting"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Select a cashbox/account for debiting!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Cashbox/account"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Make Payment
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
