import React, { useState, useEffect } from "react";

import CounterTab from "src/counters/components/CounterTab";

import { Tabs, Spin } from "antd";

import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ counters, profile, cancelPayment, verifyPayment }) => {
  const [loading, setLoading] = useState(false);

  const items = (counters || []).map((counter, index) => ({
    key: index,
    label: <span className="column-content-font">{counter.title}</span>,
    children: (
      <CounterTab {...{ counter, profile, cancelPayment, verifyPayment }} />
    ),
  }));
  useEffect(() => {
    if (!counters) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counters]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <br />
        <span className="common-info-title">All Cashboxes and Accounts</span>
        <Tabs defaultActiveKey="1" items={items}></Tabs>
      </Spin>
    </div>
  );
};
