import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Card,
  Table,
  Spin,
} from "antd";

import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentCounterToCounteragent, profile, counters }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [receiptOrder, setReceiptOrder] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values.title = "Payment of Goods Receipt";

    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};
    values.counter1 = { id: _id, title };

    values.from = _id;
    values.to = receiptOrder.counteragent.id;
    values.counteragent = receiptOrder.counteragent;
    values.receiptOrder = {
      id: receiptOrder._id,
      serial: receiptOrder.serial,
    };

    const result = await paymentCounterToCounteragent(values);
    if (result.success) {
      form.resetFields();
      message.success("Payment made!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to make payment!");
      setLoading(false);
    }
  };

  const columnsForModal = [
    {
      title: <span className="column-title-font">Name</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Weight</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="column-content-font">{text} kg</span>,
    },
    {
      title: <span className="column-title-font">Price</span>,
      dataIndex: "price",
      key: "price",
      render: (text) => <span className="column-content-font">{text} RUB</span>,
    },
  ];

  useEffect(() => {
    var receiptOrder = location.state.receiptOrder;

    receiptOrder.counteragentName = `${receiptOrder.counteragent.firm || ""} ${
      receiptOrder.counteragent.firstname || ""
    } ${receiptOrder.counteragent.lastname || ""}`;

    setReceiptOrder(receiptOrder);
    setLoading(false);
  }, []);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Pay Goods Receipt</h4>
      <Spin spinning={loading}>
        <Card size="small" title={`Goods Receipt No. ${receiptOrder.serial}`}>
          <span className="column-title-font">
            Delivered:{" "}
            {receiptOrder.cancelled
              ? "CANCELLED"
              : receiptOrder?.date?.slice(0, 5)}
          </span>

          <span className="column-title-font">Supplier:</span>
          <span className="column-content-font">
            {receiptOrder.counteragentName}
          </span>
          <br />
          <br />
          <Table
            dataSource={receiptOrder.instances}
            columns={columnsForModal}
            pagination={false}
            size="small"
          />
          <div className="common-modal__info-block">
            <div>
              <span className="column-title-font">Total Weight:</span>
              <span className="column-content-font">
                {receiptOrder.totalWeight} kg
              </span>
            </div>

            <div>
              <span className="column-title-font">Total:</span>
              <span className="column-content-font">
                {receiptOrder.totalSum} RUB
              </span>
            </div>
            <div>
              <span className="column-title-font">Paid:</span>
              {receiptOrder.cancelled ? (
                <span className="column-title-font" style={{ color: "red" }}>
                  Cancelled
                </span>
              ) : (
                <span className="column-content-font">
                  {receiptOrder.totalSumPaid} RUB
                </span>
              )}
            </div>
            <div>
              <span className="column-title-font">Comment:</span>
              <span className="column-content-font">
                {receiptOrder.comment}
              </span>
            </div>
          </div>
        </Card>
        <br />
        <h3 className="common-modal-title">Payment Details</h3>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Enter the payment amount",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Amount" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select a payment method!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Payment Method"
                optionFilterProp="children"
              >
                <Option value="cash">
                  <h4 className="option-title-font">cash</h4>
                </Option>
                <Option value="bank account">
                  <h4 className="option-title-font">bank account</h4>
                </Option>
                <Option value="card transfer">
                  <h4 className="option-title-font">card transfer</h4>
                </Option>
                <Option value="card">
                  <h4 className="option-title-font">card</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Payment Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cashbox/Account for debiting"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Select a cashbox/account for debiting!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Cashbox/account"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Pay
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
