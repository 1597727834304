import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import Routes from "./Routes";

const themeToken = {
  algorithm: theme.darkAlgorithm,
};

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ConfigProvider theme={themeToken}>
          <Routes />
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
