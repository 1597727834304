import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Spin,
} from "antd";
import SelectorWithAddingOptions from "src/common/components/SelectorWithAddingOptions";

import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ addOnBalanceOfCounteragent, profile, counteragents }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [selectedCounteragent, setSelectedCounteragent] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const selectedCounteragent = counteragents.find(
      (c) => c._id === values.counteragent
    );

    values.counteragent = {
      firstname: selectedCounteragent.firstname,
      lastname: selectedCounteragent.lastname,
      additionalName: selectedCounteragent.additionalName,
      firm: selectedCounteragent.firm,
      id: selectedCounteragent._id,
    };

    values.from = selectedCounteragent._id;

    const result = await addOnBalanceOfCounteragent(values);

    if (result.success) {
      form.resetFields();
      message.success("Balance addition successful!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to add to balance!");
      setLoading(false);
    }
  };

  const handleCounteragentSelect = (selectedValue) => {
    const selected = counteragents.find((ca) => ca._id === selectedValue);
    setSelectedCounteragent(selected);
    if (selected) {
      form.setFieldsValue({
        counteragentBalance: selected.balance,
      });
    }
  };

  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Add to Counteragent Balance</h4>
      <Spin spinning={loading}>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Select Counteragent"
            name="counteragent"
            rules={[
              {
                required: true,
                message: "Please select a counteragent!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Select a counteragent"
              filterOption={handleCounteragentFilter}
              onSelect={handleCounteragentSelect}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedCounteragent && (
            <Form.Item label="Counteragent Balance" name="counteragentBalance">
              <InputNumber
                size="small"
                style={{
                  width: "40%",
                  color:
                    selectedCounteragent?.balance < 0
                      ? "red"
                      : selectedCounteragent?.balance > 0
                        ? "green"
                        : "inherit",
                }}
                value={
                  selectedCounteragent
                    ? `Balance: ${selectedCounteragent.balance} rub.`
                    : ""
                }
                formatter={(value) => {
                  if (value) {
                    return `${value} rub`;
                  }
                }}
                readOnly
              />
            </Form.Item>
          )}
          <Form.Item
            label="Title"
            style={{ width: "40%", margin: "0px" }}
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter a title for the addition!",
              },
            ]}
          >
            <SelectorWithAddingOptions
              placeholder="Title of the addition"
              listOfOptions={[
                "salary addition",
                "addition for completed work",
                "addition for goods",
              ]}
              onChange={(value) => form.setFieldsValue({ title: value })}
            />
          </Form.Item>
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Please enter the amount!",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Amount" />
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Add to Balance
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
