import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ editDinMelPrice, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const price = location.state.price;

  form.setFieldsValue({
    title: price.title,
    price: price.price,
  });

  const onFinish = async (values) => {
    setLoading(true);

    values._id = price._id;
    values.creatorId = profile._id;
    var result = await editDinMelPrice(values);

    if (result.success) {
      message.success("Price successfully updated!");
      history.goBack();
    } else {
      message.error(result.message || "Error updating price!");
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: price.title,
      price: price.price,
    });
    setLoading(false);
  }, []);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Edit Price</h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item label="Product Name" name="title">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: true, message: "Please enter the price!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Price
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
