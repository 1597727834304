import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Card,
  Table,
  Spin,
} from "antd";

import "dayjs/locale/en";
import locale from "antd/es/date-picker/locale/en_US";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentCounteragentToCounter, profile, counters }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values.title = "Order Payment";

    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};

    values.counter1 = { id: _id, title };

    values.to = values.counter1.id;
    values.from = order.counteragent.id;
    values.counteragent = order.counteragent;
    values.order = {
      id: order._id,
      serial: order.serial,
    };

    const result = await paymentCounteragentToCounter(values);

    if (result.success) {
      form.resetFields();
      message.success("Payment accepted!");
      history.goBack();
    } else {
      message.error(result.message || "Failed to accept payment!");
      setLoading(false);
    }
  };

  const columnsForModal = [
    {
      title: <span className="column-title-font">Name</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Weight</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="column-content-font">{text} kg</span>,
    },
    {
      title: <span className="column-title-font">Price</span>,
      dataIndex: "price",
      key: "price",
      render: (text) => <span className="column-content-font">{text} RUB</span>,
    },
  ];

  useEffect(() => {
    var order = location.state.order;

    order.counteragentName = `${order.counteragent.firm || ""} ${
      order.counteragent.firstname || ""
    } ${order.counteragent.lastname || ""}`;

    setOrder(order);
    setLoading(false);
  }, []);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Pay Order</h4>
      <Spin spinning={loading}>
        <Card size="small" title={`Order No. ${order.serial}`}>
          <span className="column-title-font">Buyer:</span>
          <span className="column-content-font">{order.counteragentName}</span>
          <br />
          <br />
          <Table
            dataSource={order.instances}
            columns={columnsForModal}
            pagination={false}
            size="small"
          />

          <div className="common-modal__info-block">
            <div className="common-modal__info-container">
              <span className="column-title-font">Total Weight:</span>
              <span className="column-content-font">
                {order.totalWeight} kg
              </span>
            </div>

            <div className="common-modal__info-container">
              <span className="column-title-font">Total:</span>
              <span className="column-content-font">{order.totalSum} RUB</span>
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Paid:</span>
              {order.paid ? (
                <span className="column-title-font" style={{ color: "green" }}>
                  Yes
                </span>
              ) : (
                <span className="column-content-font" style={{ color: "red" }}>
                  No
                </span>
              )}
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Total Paid:</span>
              {order.cancelled ? (
                <span className="column-title-font" style={{ color: "red" }}>
                  Cancelled
                </span>
              ) : (
                <span className="column-content-font">
                  {order.totalSumPaid} RUB
                </span>
              )}
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Shipped:</span>
              {order.shipped ? (
                <span className="column-title-font" style={{ color: "green" }}>
                  Yes
                </span>
              ) : (
                <span className="column-content-font" style={{ color: "red" }}>
                  No
                </span>
              )}
            </div>
            {order.comment && (
              <div className="common-modal__info-container">
                <span className="column-title-font">Comment:</span>
                <span className="column-content-font">{order.comment}</span>
              </div>
            )}
          </div>
        </Card>
        <h3 className="common-modal-title">Payment Details</h3>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Enter the payment amount",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Amount" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select a payment method!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Payment Method"
                optionFilterProp="children"
              >
                <Option value="cash">
                  <h4 className="option-title-font">cash</h4>
                </Option>
                <Option value="bank account">
                  <h4 className="option-title-font">bank account</h4>
                </Option>
                <Option value="card transfer">
                  <h4 className="option-title-font">card transfer</h4>
                </Option>
                <Option value="card">
                  <h4 className="option-title-font">card</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Payment Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cashbox/Account for crediting"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Select a cashbox/account for crediting!",
              },
            ]}
          >
            <Select
              placeholder="Cashbox/account"
              size="small"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Comment" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Accept Payment
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
