import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";

import { useHistory } from "react-router-dom";

import { Table, Card, Modal, Button, Space, Input, message, Spin } from "antd";

import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import "./index.css";

const { formatDate } = commonFunc;

export default ({ analyses, cancelAnalysis, profile }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const comeback = () => {
    history.push("/processing");
  };

  const filterAnalyses = (analyses, searchValue) => {
    let filteredData = analyses || [];

    if (searchValue) {
      filteredData = filteredData.filter((analysis) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          (analysis.date &&
            analysis.date.toLowerCase().includes(searchValueLower)) ||
          (analysis.wheat &&
            analysis.wheat.toLowerCase().includes(searchValueLower)) ||
          (analysis.flour &&
            analysis.flour.toLowerCase().includes(searchValueLower)) ||
          analysis.serial === searchNumber
        );
      });
    }

    return filteredData;
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((analysis) => ({
      ...analysis,
      key: analysis._id,
      date: formatDate(analysis.date).slice(0, 10),
    }));
  };

  const handleEditAnalysis = async (values) => {
    history.push({
      pathname: `/processing/analysis/edit`,
      state: { analysis: values },
    });
  };

  const handleCancelAnalysis = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelAnalysis(values);

    if (result.success) {
      message.success("Analysis successfully canceled!");
    } else {
      message.error(result.message || "Failed to cancel!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (analysis) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this record?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, cancel",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleCancelAnalysis(analysis);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (analysis) => {
    setSelectedAnalysis(analysis);
    setIsModalVisible(true);
  };

  const AnalysisModalContent = () => {
    if (!selectedAnalysis) {
      return null;
    }

    const { date, serial, wheat, flour, cancelled } = selectedAnalysis;

    return (
      <Card
        size="small"
        title={`Analysis №${serial}`}
        extra={
          <span className="column-title-font">
            Date: {cancelled ? "CANCELLED" : date.slice(0, 10)}
          </span>
        }
      >
        <div className="common-modal__info-block">
          <div>
            <span className="column-title-font">Flour data:</span>
            <span className="column-content-font">{flour}</span>
          </div>
          <div>
            <span className="column-title-font">Wheat data:</span>
            <span className="column-content-font">{wheat}</span>
          </div>
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: <CalendarOutlined />,
      dataIndex: "serial",
      key: "serial",
      render: (text, record) => (
        <span className="column-content-font">{record.date}</span>
      ),
    },
    {
      title: <span className="column-title-font">Wheat Analysis</span>,
      dataIndex: "wheat",
      key: "wheat",
      render: (text, record) =>
        record.cancelled ? (
          <span className="column-title-font">cancelled</span>
        ) : (
          <span className="column-content-font">{text}</span>
        ),
    },
    {
      title: <span className="column-title-font">Flour Analysis</span>,
      dataIndex: "flour",
      key: "flour",
      render: (text, record) =>
        record.cancelled ? (
          <span className="column-title-font">cancelled</span>
        ) : (
          <span className="column-content-font">{text}</span>
        ),
    },
    {
      title: <span className="column-title-font">Actions</span>,
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            className="common-table-button"
            size="small"
            type="link"
            onClick={() => showModal(record)}
            icon={<EyeOutlined />}
          />
          <Button
            disabled={record.cancelled}
            className="common-table-button"
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEditAnalysis(record)}
          />
          <Button
            disabled={record.cancelled}
            className="common-table-button"
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showConfirmCancel(record)}
            danger
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!analyses) {
      setLoading(true);
      return;
    }

    const analysesFiltered = filterAnalyses(analyses, searchValue);

    const dataSource = prepareDataSource(analysesFiltered);
    setDataSource(dataSource);

    setLoading(false);
  }, [analyses, searchValue]);

  useEffect(() => {
    if (history.location.state?.listViewState) {
      const { isModalVisible, searchValue } =
        history.location.state.listViewState;
      setIsModalVisible(isModalVisible);
      setSearchValue(searchValue);
    }
  }, [history.location.state]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <Button type="ghost" onClick={comeback} className="back-route-button">
          <LeftOutlined />
          <h4 className="back-route-button--title">Back</h4>
        </Button>

        <br />
        <h4 className="common-title--large">Analysis Journal</h4>
        <Input.Search
          value={searchValue}
          placeholder="Search"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />

        <Table
          rowClassName={(record) => {
            if (record.cancelled) return "cancelled";
            return "";
          }}
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <AnalysisModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
