import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Button, message, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ editStorehouseInstance, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const instance = location.state.instance;

  const onFinish = async (values) => {
    setLoading(true);
    values._id = instance._id;
    values.creatorId = profile._id;
    var result = await editStorehouseInstance(values);

    if (result.success) {
      message.success("Type (product/raw material) successfully updated!");
      history.goBack();
    } else {
      message.error(
        result.message || "Error updating type (product/raw material)!"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (instance) {
      form.setFieldsValue({
        title: instance.title,
        type: instance.type,
      });
      setLoading(false);
    }
  }, [instance, form]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
          Edit Type (Product/Raw Material)
        </h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item
            label="Type Name (Product/Raw Material)"
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter the type name (Product/Raw Material)!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            requiredMark="hidden"
            label="Type Category (Product/Raw Material)"
            name="type"
            rules={[
              {
                required: true,
                message:
                  "Please select the type category (Product/Raw Material)!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="product">Product</Radio>
              <Radio value="rawMaterial">Raw Material</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Type (Product/Raw Material)
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
