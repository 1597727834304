import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";

import {
  Table,
  Card,
  Modal,
  Button,
  Space,
  Input,
  message,
  Radio,
  Spin,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckSquareOutlined,
  FieldNumberOutlined,
  CalendarOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

const { formatDate } = commonFunc;

export default ({ counter, profile, cancelPayment, verifyPayment }) => {
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [columns, setColumns] = useState([]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const filterPayments = (filter, counter, searchValue) => {
    let filteredData = counter.payments || [];

    for (var payment of counter.payments) {
      if (payment.from === counter._id) {
        payment.income = false;
      } else {
        payment.income = true;
      }
    }

    if (searchValue) {
      filteredData = filteredData.filter((payment) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          payment.counteragent?.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.firm
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.serial === searchNumber ||
          payment.type.toLowerCase().includes(searchValueLower) ||
          payment.title.toLowerCase().includes(searchValueLower) ||
          payment.order?.serial === searchNumber ||
          payment.receiptOrder?.serial === searchNumber ||
          payment.sum === searchNumber
        );
      });
    }

    switch (filter) {
      case "notVerified":
        return filteredData.filter(
          (payment) => !payment.verified && !payment.cancelled
        );
      case "verified":
        return filteredData.filter((payment) => payment.verified);
      case "cancelled":
        return filteredData.filter((payment) => payment.cancelled);
      case "income":
        return filteredData.filter((payment) => payment.income);
      case "outcome":
        return filteredData.filter((payment) => !payment.income);
      default:
        return filteredData;
    }
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((payment, index) => ({
      ...payment,
      key: payment._id,
      date: formatDate(payment.date).slice(0, 100),
      counteragentName: `${payment.counteragent?.firm || ""} ${
        payment.counteragent?.lastname || ""
      } ${payment.counteragent?.firstname || ""}
      ${payment.counteragent?.additionalName || ""}`,
    }));
  };

  const handleCancelPayment = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelPayment(values);

    if (result.success) {
      message.success("Operation successfully canceled!");
      setLoading(false);
    } else {
      message.error(result.message || "Failed to cancel!");
      setLoading(false);
    }
  };
  const handleVerify = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await verifyPayment(values);

    if (result.success) {
      message.success("Payment verified!");
      setLoading(false);
    } else {
      message.error(result.message || "Failed to verify!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (order) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this payment?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, cancel",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleCancelPayment(order);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };
  const showConfirmVerifiing = (payment) => {
    Modal.confirm({
      title: "Are you sure you want to verify this payment?",
      icon: <ExclamationCircleOutlined />,
      content: 'This action changes the payment status to "verified".',
      okText: "Yes, verify",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleVerify(payment);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (payment) => {
    setSelectedPayment(payment);
    setIsModalVisible(true);
  };

  const PaymentModalContent = () => {
    if (!selectedPayment) {
      return null;
    }

    const {
      date,
      serial,
      counteragentName,
      title,
      type,
      sum,
      from,
      comment,
      cancelled,
      verified,
      counter1,
      counter2,
      order,
      receiptOrder,
    } = selectedPayment;

    return (
      <Card
        size="small"
        title={
          <span className="column-title-font">{`Payment Operation No. ${serial}`}</span>
        }
        extra={
          <span className="column-title-font">
            Made: {cancelled ? "CANCELLED" : date.slice(0, 105)}
          </span>
        }
      >
        <div className="common-modal__info-block">
          <div className="common-modal__info-container">
            <div className="common-modal__info-container">
              <span className="column-title-font">Operation:</span>
              <span className="column-content-font">{title}</span>
            </div>
            {counteragentName && counteragentName.trim() !== "" && (
              <div>
                <span className="column-title-font">Counteragent:</span>
                <span className="column-content-font">{counteragentName}</span>
              </div>
            )}
            {order && (
              <div>
                <span className="column-title-font">Order:</span>
                <span className="column-content-font">No. {order.serial}</span>
              </div>
            )}
            {receiptOrder && (
              <div>
                <span className="column-title-font">Receipt Order:</span>
                <span className="column-content-font">
                  No. {receiptOrder.serial}
                </span>
              </div>
            )}
            {counter2 ? (
              <div className="common-modal__info-block">
                <div className="common-modal__info-container">
                  <span className="column-title-font">Transfer from:</span>
                  <span className="column-content-font">{counter1.title}</span>
                </div>
                <div className="common-modal__info-container">
                  <span className="column-title-font">To counter:</span>
                  <span className="column-content-font">{counter2.title}</span>
                </div>
              </div>
            ) : counter._id === from ? (
              <div className="common-modal__info-container">
                <span className="column-title-font">Payout from:</span>
                <span className="column-content-font">{counter1.title}</span>
              </div>
            ) : (
              <div className="common-modal__info-container">
                <span className="column-title-font">Payment to:</span>
                <span className="column-content-font">{counter1.title}</span>
              </div>
            )}
          </div>

          <div className="common-modal__info-container">
            <span className="column-title-font">Amount:</span>
            <span className="column-content-font">{sum} RUB</span>
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Method:</span>
            <span className="column-content-font">{type}</span>
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Verified:</span>
            {verified ? (
              <span className="column-title-font" style={{ color: "green" }}>
                Yes
              </span>
            ) : (
              <span className="column-content-font" style={{ color: "red" }}>
                No
              </span>
            )}
          </div>
          {comment && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Comment:</span>
              <span className="column-content-font">{comment}</span>
            </div>
          )}
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const columns = [
      {
        title: (
          <div>
            <FieldNumberOutlined /> <CalendarOutlined />
          </div>
        ),
        dataIndex: "serial",
        key: "serial",
        render: (_, record) => (
          <span className="column-content-font">
            {record.serial} / {record.date}
          </span>
        ),
      },
      {
        title: <span className="column-content-font">+/-</span>,
        dataIndex: "serial",
        key: "serial",
        render: (_, record) => (
          <span className="column-content-font">
            {record.income ? (
              <ArrowLeftOutlined style={{ color: "green", fontSize: "15px" }} />
            ) : (
              <ArrowRightOutlined style={{ color: "blue", fontSize: "15px" }} />
            )}
          </span>
        ),
      },
      {
        title: <span className="table-title-font">Title</span>,
        dataIndex: "title",
        key: "title",
        render: (text) => <span className="column-content-font">{text}</span>,
      },
      {
        title: <span className="table-title-font">Amount</span>,
        dataIndex: "sum",
        key: "sum",
        render: (text, record) =>
          record.cancelled ? (
            <span className="table-title-font">CANCELLED</span>
          ) : (
            <span className="column-content-font">{text} RUB</span>
          ),
      },
      {
        title: <span className="table-title-font">Actions</span>,
        key: "action",
        render: (text, record) => (
          <Space>
            <Button
              disabled={record.verified || record.cancelled}
              className="common-table-button"
              type="link"
              size="small"
              icon={
                <CheckSquareOutlined
                  style={{
                    color: record.cancelled
                      ? "inherit"
                      : record.verified
                        ? "green"
                        : "red",
                  }}
                />
              }
              onClick={() =>
                profile.rang === "admin" ? showConfirmVerifiing(record) : null
              }
            />
            <Button
              className="common-table-button"
              size="small"
              type="link"
              onClick={() => showModal(record)}
              icon={<EyeOutlined />}
            />
            <Button
              disabled={record.cancelled || record.verified}
              className="common-table-button"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => showConfirmCancel(record)}
              danger
            />
          </Space>
        ),
      },
    ];
    const counteragentColumn = {
      title: <span className="column-title-font">Counteragent</span>,
      dataIndex: "counteragentName",
      key: "counteragentName",
      render: (text) => (
        <span className="column-content-font">{`${
          text.trim() !== "" ? text : "- - -"
        }`}</span>
      ),
    };

    if (windowWidth > 375) {
      columns.splice(1, 0, counteragentColumn);
    }

    setColumns(columns);
  }, [windowWidth]);

  useEffect(() => {
    if (!counter) {
      setLoading(true);
      return;
    }

    const filteredPayments = filterPayments(filter, counter, searchValue);

    const dataSource = prepareDataSource(filteredPayments);
    setDataSource(dataSource);

    setLoading(false);
  }, [filter, counter, searchValue]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <div className="common-modal__info-container">
          <span className="common-info-title">Balance in</span>

          <span className="common-info-title">"{counter.title}" :</span>
          <span className="common-info-title">{counter.balance} RUB</span>
        </div>

        <span className="common-info-title">All Financial Transactions</span>

        <Input.Search
          placeholder="Search by buyer's surname/firm/serial number"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value="income">Incoming</Radio.Button>
          <Radio.Button value="outcome">Outgoing</Radio.Button>
          <Radio.Button value="notVerified">Not Verified</Radio.Button>
          <Radio.Button value="verified">Verified</Radio.Button>
          <Radio.Button value="cancelled">Cancelled</Radio.Button>
        </Radio.Group>
        <Table
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <PaymentModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
