import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";

import { useHistory } from "react-router-dom";

import { Table, Card, Modal, Button, Space, Input, message, Spin } from "antd";

import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FieldNumberOutlined,
  CalendarOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import "./index.css";

const { formatDate } = commonFunc;

export default ({ repairs, cancelRepair, profile }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const comeback = () => {
    history.push("/processing");
  };

  const filterRepairs = (repairs, searchValue) => {
    let filteredData = repairs || [];

    if (searchValue) {
      filteredData = filteredData.filter((repair) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          (repair.date &&
            repair.date.toLowerCase().includes(searchValueLower)) ||
          (repair.comment &&
            repair.comment.toLowerCase().includes(searchValueLower)) ||
          (repair.scope &&
            repair.scope.toLowerCase().includes(searchValueLower)) ||
          (repair.title &&
            repair.title.toLowerCase().includes(searchValueLower)) ||
          (repair.replacedParts &&
            repair.replacedParts.toLowerCase().includes(searchValueLower)) ||
          repair.serial === searchNumber ||
          repair.repairTime === searchNumber ||
          repair.totalSum === searchNumber
        );
      });
    }

    return filteredData;
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((repair, index) => ({
      ...repair,
      key: repair._id,
      date: formatDate(repair.date).slice(0, 10),
    }));
  };

  const handleEditRepair = async (values) => {
    history.push({
      pathname: `/processing/repair/edit`,
      state: { repair: values },
    });
  };

  const handleCancelRepair = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelRepair(values);

    if (result.success) {
      message.success("Repair successfully cancelled!");
    } else {
      message.error(result.message || "Failed to cancel!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (repair) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this repair?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, cancel",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleCancelRepair(repair);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (repair) => {
    setSelectedRepair(repair);
    setIsModalVisible(true);
  };

  const RepairModalContent = () => {
    if (!selectedRepair) {
      return null;
    }

    const {
      date,
      serial,
      repairTime,
      scope,
      title,
      replacedParts,
      comment,
      cancelled,
    } = selectedRepair;

    return (
      <Card
        size="small"
        title={`Repair №${serial}`}
        extra={
          <span className="column-title-font">
            Date: {cancelled ? "CANCELLED" : date.slice(0, 10)}
          </span>
        }
      >
        <div className="common-modal__info-block">
          <div>
            <span className="column-title-font">Mill or unit repaired:</span>
            <span className="column-content-font">{scope}</span>
          </div>
          <span className="column-title-font">Repair title:</span>
          <span className="column-content-font">{title}</span>
          <div>
            <span className="column-title-font">Hours spent:</span>
            <span className="column-content-font">{repairTime}</span>
          </div>
        </div>

        <div className="common-modal__info-block">
          <div>
            <span className="column-title-font">Replaced parts:</span>
            <span className="column-content-font">{replacedParts}</span>
          </div>

          <div>
            <span className="column-title-font">
              Comments, repair information:
            </span>
            <span className="column-content-font">{comment}</span>
          </div>
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: (
        <div>
          <CalendarOutlined /> <FieldNumberOutlined />
        </div>
      ),
      dataIndex: "serial",
      key: "serial",
      render: (text, record) => (
        <span className="column-content-font">
          {record.date} / {record.serial}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Mill/Equipment</span>,
      dataIndex: "scope",
      key: "scope",
      render: (text, record) =>
        record.cancelled ? (
          <span className="column-title-font"></span>
        ) : (
          <span className="column-content-font">{text}</span>
        ),
    },
    {
      title: <span className="column-title-font">Repair title</span>,
      dataIndex: "title",
      key: "title",
      render: (text, record) =>
        record.cancelled ? (
          <span className="column-title-font">cancelled</span>
        ) : (
          <span className="column-content-font">{text}</span>
        ),
    },
    {
      title: <span className="column-title-font">Actions</span>,
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            className="common-table-button"
            size="small"
            type="link"
            onClick={() => showModal(record)}
            icon={<EyeOutlined />}
          />
          <Button
            disabled={record.cancelled}
            className="common-table-button"
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEditRepair(record)}
          />
          <Button
            disabled={record.cancelled}
            className="common-table-button"
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showConfirmCancel(record)}
            danger
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!repairs) {
      setLoading(true);
      return;
    }

    const repairsFiltered = filterRepairs(repairs, searchValue);

    const dataSource = prepareDataSource(repairsFiltered);
    setDataSource(dataSource);

    setLoading(false);
  }, [repairs, searchValue]);

  useEffect(() => {
    if (history.location.state?.listViewState) {
      const { isModalVisible, searchValue } =
        history.location.state.listViewState;
      setIsModalVisible(isModalVisible);
      setSearchValue(searchValue);
    }
  }, [history.location.state]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <Button type="ghost" onClick={comeback} className="back-route-button">
          <LeftOutlined />
          <h4 className="back-route-button--title">Back</h4>
        </Button>

        <br />
        <h4 className="common-title--large">Repair Log</h4>
        <Input.Search
          value={searchValue}
          placeholder="Search"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />

        <Table
          rowClassName={(record) => {
            if (record.cancelled) return "cancelled";
            return "";
          }}
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <RepairModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
